import { FullWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import { graphql } from 'gatsby';
import React from 'react';
import PlainHero, { PlainHeroData } from 'components/HeroBanner/PlainHero';
import Layout, { PageType } from 'components/Layout';
import { CsBlock, mapBlocks } from 'helpers/blockMapper';
import { usePageTracking } from 'helpers/usePageTracking';
import { CsMeta } from 'types/contentStack';

export type IneligibleQuotePageProps = {
  data: IneligibleQuotePageData;
  pageType: PageType;
};

export type IneligibleQuotePageData = {
  csIneligibleQuotePage: {
    meta: CsMeta;
    hero: PlainHeroData;
    blocks: CsBlock[];
  };
};

export const query = graphql`
  fragment IneligibleQuotePage on cs__ineligible_quote_page {
    meta {
      meta_title
    }
    ...PlainHeroIneligibleQuotePage
    blocks {
      ...IntroBlockIneligibleQuotePage
      ...FeaturesBlockIneligibleQuotePage
    }
  }
`;

const IneligibleQuotePage: React.FC<IneligibleQuotePageProps> = ({ data, pageType }) => {
  const page = data.csIneligibleQuotePage;

  usePageTracking(page.meta.meta_title);

  return (
    <Layout meta={page.meta} pageType={pageType}>
      <PlainHero hero={page.hero} />
      {page.blocks && mapBlocks(page.blocks)}
      <FullWidthDivider />
    </Layout>
  );
};

export default IneligibleQuotePage;

import { graphql } from 'gatsby';
import React from 'react';
import IneligibleQuotePageTemplate, {
  IneligibleQuotePageProps,
} from 'templates/IneligibleQuotePage';

export const query = graphql`
  query {
    csIneligibleQuotePage(page_id: { eq: "motor-ineligible-quote" }) {
      page_id
      ...IneligibleQuotePage
    }
  }
`;

const IneligibleQuotePage: React.FC<IneligibleQuotePageProps> = ({ data }) => {
  if (!data.csIneligibleQuotePage) {
    throw new Error(
      'Ineligible quote page with page_id `motor-ineligible-quote` not found'
    );
  }

  return <IneligibleQuotePageTemplate data={data} pageType="quoteAndBuy" />;
};

export default IneligibleQuotePage;
